<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="columns panel" style="padding: 1em 1em 0.5em">
          <div class="column is-2">
            <b-field label="ຈາກວັນທີ">
              <b-datepicker
                v-model="fromdate"
                icon="calendar-today"
                @input="changeFromDate"
                :locale="this.getDateLocale"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="ຫາວັນທີ">
              <b-datepicker
                v-model="todate"
                icon="calendar-today"
                @input="changeTodate"
                :locale="this.getDateLocale"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="ສະຖານະ">
              <b-select v-model="status" placeholder="ສະຖານະ" required expanded>
                <option
                  v-for="(item, index) in statuses"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.value }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="ເລກທີໃບນໍາສົ່ງ">
              <b-input
                v-model="shipmentscriptno"
                name="shipmentscriptno"
                required
                @keyup.native.enter="search()"
                placeholder="ເລກທີໃບນໍາສົ່ງ"
              />
            </b-field>
          </div>
          <div class="column is-1" style="padding-top: 2.7em">
            <b-button class="is-info" @click="search()" icon-left="magnify">
            </b-button>
          </div>
        </div>

        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
            label="ເລກທີໃບນໍາສົ່ງ"
            field="code"
            sortable
            v-slot="props"
          >
            {{ props.row.code }}
          </b-table-column>
          <b-table-column
            label="ວັນທີ"
            field="created_time"
            sortable
            v-slot="props"
          >
            {{ props.row.created_time | datetime }}
          </b-table-column>
          <b-table-column
            label="ສາຂາຕົ້ນທາງ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.branch.code }} - {{ props.row.branch.name }}
          </b-table-column>
          <b-table-column
            label="ປ້າຍລົດ"
            field="sendername"
            sortable
            v-slot="props"
          >
            {{ props.row.vehicle.license_no }}
          </b-table-column>
          <b-table-column label="ສະຖານະ" field="status" sortable v-slot="props">
            <span v-if="props.row.status == 'waiting'" class="tag is-danger">
              ລໍຖ້າຮັບ
            </span>
            <span v-if="props.row.status == 'received'" class="tag is-success">
              ຮັບແລ້ວ
            </span>
            <span
              v-if="props.row.status == 'partial_received'"
              class="tag is-warning"
            >
              ຮັບບາງສ່ວນ
            </span>
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-info"
                @click="view(props.row)"
                icon-left="eye"
              >
              </b-button>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>


      <b-loading
          :is-full-page="true"
          v-model="loadingFlag"
          :can-cancel="false"
      ></b-loading>

  </div>
</template>

<script>
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import moment from "moment";
import swal from "sweetalert2";
export default {
  name: "LoadParcelsFromBus",
  components: { EmptyTable, CardComponent },
  data() {
    return {
      statuses: [
        { id: "waiting", value: "ລໍຖ້າຮັບ" },
        { id: "partial_received", value: "ຮັບບາງສ່ວນ" },
        { id: "received", value: "ຮັບແລ້ວ" },
      ],
      activeTab: 0,
      currentPage: 1,
      perPage: 20,
      title: "ກວດເຄື່ອງລົງລົດ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      shipmentscriptno: null,
      fromdate: null,
      todate: null,
      status: "waiting",
      loadingFlag: false
    };
  },
  mounted() {
    const start = new Date();
    start.setDate(start.getDate() -2)
    this.fromdate = start
    this.todate = new Date();
    this.search();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    view(item) {
      this.$router.push({ path: "/ShipmentScriptView/" + item.code });
    },
    search() {
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      const url = `parcels/loadfrombus/${fdate}/${tdate}/${this.status}`;
      axios.get(url).then((r) => {
        this.models = r;
        if (this.shipmentscriptno) {
          this.models = this._.filter(
            r,
            (e) => e.code == this.shipmentscriptno
          );
          if (this.models && this.models.length == 1) {
            this.$router.push({
              path: "/ShipmentScriptView/" + this.models[0].code,
            });
          }
        }
      });
    },
    receive() {
      if (this.shipmentscriptno) {
        this.loadingFlag = true;
        axios
            .post(`parcels/doloadfrombus`, {
              shipmentscriptno: this.shipmentscriptno,
            })
            .then((r) => {
              this.loadingFlag = false;
              console.log(r);
              swal.fire("ສຳເລັດ", "", "success");
              this.search();
            }).catch(() => {
          this.loadingFlag = false;
        });
      }
    },
    changeFromDate(e) {
      this.fromdate = e;
    },
    changeTodate(e) {
      this.todate = e;
    },
  },
};
</script>